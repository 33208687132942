/*TODO: the styles are mess... importing on top of each other... should be reorganized if extended*/

div.poesy-container {
    font-family: monospace;
    text-align: center;
  }
  
  .fading-text-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .fading-text {
    font-size: 32px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .fading-text.active {
    opacity: 1;
  }

  
.imposing-button:hover {
  background-color: limegreen;
  position: absolute;
}
.imposing-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: white;
  background-color: black;
  cursor: pointer;

  font: inherit;
  font-size: 32px;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

body {
  background-color: bla;
}