/*TODO: the styles are mess... importing on top of each other... should be reorganized if extended*/

.q-poesy-container {
  font-family: monospace;
  text-align: center;
}

.fading-text-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fading-text {
  font-size: 32px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  width: 100%;
  height: 100%;
}

.fading-text.active {
  opacity: 1;
}
.halo-container {
  display: flex;
  justify-content: center;
}

.cloud.left {
  position: relative;
  top: 12px;
  transform: rotate(180deg);
}
.cloud.right {
  position: relative;
  bottom: 12px;
}

.halo-container-inner {
  position: absolute;
  top: 90px;
}