button.back {
    background: none!important;
    border: none;
    font-size: 50px;
    padding-left: 50px!important;
    padding-top: 25px!important;
    padding-bottom: 25px!important;
    /*optional*/
    /*input has OS specific font-family*/
    cursor: pointer;
  }


button.poesy-description {
  background: none!important;
    border: none;
    font-size: 10px;
    margin-top: 4px;
    position:absolute;
    /*optional*/
    /*input has OS specific font-family*/
    cursor: pointer;
}

.poesy-in-index {
  position: relative;
  padding-bottom: 15px;
}

.closed-description {
  margin-top: 15px;
  text-align: left;
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}

.open-description {
  margin-top: 15px;
  text-align: left;
  height: 200px;
  overflow: scroll;
  transition: height 0.5s;
}


.gradient {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 15%;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
}